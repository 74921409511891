import "./src/styles/global.css";
import React from "react";
import TopLayout from "./src/components/TopLayout/TopLayout";
// import { StyledEngineProvider } from '@mui/material/styles';
import Layout from "./src/components/layout/layout";
import loadable from "@loadable/component";
import { WrapPageElementBrowserArgs } from "gatsby";
const LoadableTawkMessenger = loadable.lib(
  () => import("@tawk.to/tawk-messenger-react")
);

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>;
};

export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementBrowserArgs) => {
  return (
    <>
      <Layout location={props.location}>{element}</Layout>
      <LoadableTawkMessenger>
        {({ default: TawkMessenger }) =>
          TawkMessenger ? (
            <TawkMessenger
              propertyId={process.env.GATSBY_TAWK_PROPERTY_ID}
              widgetId={process.env.GATSBY_TAWK_WIDGET_ID}
            />
          ) : null
        }
      </LoadableTawkMessenger>
    </>
  );
};
