import React, { useEffect, useState, useContext, FC } from "react";
import {
  HeaderHeightSetContext,
  HeaderHeightStateContext,
} from "../../../context/headerHeightContext";
import { Link, useStaticQuery, graphql } from "gatsby";
import { WindowLocation } from "@reach/router";
import ContactList from "./ContactListHeader";
import MainNavigation from "./MainNavigation";
import clsx from "clsx";
import useDimensions from "react-cool-dimensions";
import { cn } from "@/lib/utils";
import { StaticImage } from "gatsby-plugin-image";
import LogoMain from "../../../images/logo-main-compressed.svg";

interface HeaderProps {
  contactInfo: {
    email: string;
    phone: string;
    phoneText: string;
    workingTime: string;
    facebookLink: string;
  };
  logoOld: string;
  location: WindowLocation;
  scrolled: boolean;
  setScrolled: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: FC<HeaderProps> = ({
  contactInfo,
  logoOld,
  location,
  scrolled,
  setScrolled,
}) => {
  const { observe } = useDimensions({
    useBorderBoxSize: true, // Tell the hook to measure based on the border-box size, default is false
    onResize: ({ height, observe, unobserve }) => {
      if (headerHeight > height && scrolled) return;
      setHeaderHeight(Math.round(height) || 0);
      unobserve();
      observe();
    },
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const setHeaderHeight = useContext(HeaderHeightSetContext);
  const headerHeight = useContext(HeaderHeightStateContext);

  const { logoMain } = useStaticQuery(graphql`
    query MainLogoQuery {
      logoMain: file(relativePath: { eq: "logo-main.svg" }) {
        publicURL
      }
    }
  `);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header
      ref={observe}
      className={clsx(
        "top-0 inset-x-0 z-[2] bg-greenDark p-[30px] pt-11 transition-opacity",
        "max-800:px-5 max-800:py-3",
        "max-[360px]:px-5",
        {
          "fixed mui-fixed pt-2 pb-2 max-[360px]:px-3": scrolled,
          "opacity-80": scrolled && !Boolean(anchorEl),
        }
      )}
    >
      <div className="max-w-screen-mui-md mx-auto">
        <div className="mui-md:px-4">
          <div className="grid grid-cols-[auto_auto] mui-md:grid-cols-[1fr_5fr] items-center justify-between gap-4">
            <div className="mui-sm:shrink-0">
              <Link
                to="/"
                className={cn("group block relative w-[114px] h-[61px]", {
                  "max-desktop:w-[85px] max-desktop:h-[45px]": scrolled,
                })}
              >
                <StaticImage
                  loading="eager"
                  src="../../../images/logo-old.png"
                  placeholder="dominantColor"
                  layout="constrained"
                  quality={15}
                  alt="farmeqplus logo 1"
                  width={114}
                  className={cn(
                    [
                      "w-[114px] h-auto block absolute top-1/2 transform -translate-y-1/2 left-0 opacity-100 transition-all duration-500",
                      "max-mui-md:transition-none mui-md:opacity-0 mui-md:-translate-y-1/2 mui-md:scale-0",
                      "mui-md:group-hover:opacity-100 mui-md:group-hover:-translate-y-1/2 mui-md:group-hover:scale-100 mui-md:group-hover:rotate-[360deg]",
                    ],
                    {
                      "max-desktop:w-[85px]": scrolled,
                    }
                  )}
                />
                <LogoMain
                  alt="farmeqplus logo 2"
                  width={114}
                  className={cn(
                    [
                      "w-[114px] h-auto block absolute top-1/2 -translate-y-1/2 left-0 opacity-0 transition-all duration-500",
                      "max-mui-md:transition-none mui-md:opacity-100 mui-md:-translate-y-1/2 mui-md:scale-[1.4]",
                      "mui-md:group-hover:opacity-0",
                    ],
                    {
                      "max-desktop:w-[85px]": scrolled,
                    }
                  )}
                />
              </Link>
            </div>
            <div className="flex-grow">
              <div className="grid max-desktop:grid-cols-[1fr_auto] items-center gap-2">
                <ContactList
                  contactInfo={contactInfo}
                  variant="horizontal"
                  className={cn(!scrolled && "max-portraitMode:hidden")}
                />
                <div className="desktop:hidden w-full">
                  <MainNavigation
                    location={location}
                    variant="menu"
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    onClick={handleClick}
                  />
                </div>
                <div className="max-[959px]:hidden">
                  <MainNavigation
                    location={location}
                    variant="list"
                    anchorEl={anchorEl}
                    onClose={() => {}}
                    onClick={() => {}}
                  />
                </div>
              </div>
            </div>
          </div>
          <ContactList
            contactInfo={contactInfo}
            variant="vertical"
            className={cn("portraitMode:hidden", scrolled && "hidden")}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
