import React, { FC, useContext } from "react";
import Link from "../../Link";
import { Badge } from "@mui/material";
import { MyPriceListStateContext } from "../../../context/myPriceListContext";
import Menu from "../../../../assets/icons/hamburger-menu.svg";
import MobileMenu from "./MobileMenu";
import {
  GRAIN_CONVEYORS,
  GRAIN_ELEVATORS,
  INCLINE_CONVEYORS,
} from "../../../const";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

const navigationMenu = [
  {
    caption: GRAIN_CONVEYORS,
    link: "/new-conveyors",
  },
  {
    caption: GRAIN_ELEVATORS,
    link: "/new-elevators",
  },
  {
    caption: INCLINE_CONVEYORS,
    link: "/incline-conveyors",
  },
  // {
  //   caption: GRAIN_AUGERS,
  //   link: '/new-augers',
  // },
  // {
  //   caption: 'Used equipment',
  //   link: 'https://nasseq.net/catalog',
  // },
  {
    caption: "Contact",
    link: "/contact",
  },
  {
    caption: "All price lists",
    link: "/all-price-lists",
  },
  {
    caption: "My price list",
    link: "/my-price-list",
  },
];

const normalizePath = (path: string) => path.replace(/\/+$/, "");

interface MainNavigationProps {
  location: Location;
  variant: "menu" | "list";
  anchorEl: null | HTMLElement;
  onClose: () => void;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const MainNavigation: FC<MainNavigationProps> = ({
  location,
  variant,
  anchorEl,
  onClose,
  onClick,
}) => {
  const myPriceList = useContext(MyPriceListStateContext);
  const open = Boolean(anchorEl);

  if (variant === "menu") {
    return (
      <Badge
        overlap="rectangular"
        variant="dot"
        showZero={true}
        invisible={!myPriceList.length}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        classes={{ badge: "top-5 bg-[#EC5E0F]" }}
      >
        <Button onClick={onClick} size={"iconLg"} className="rounded-full">
          <Menu alt="menu button" className="w-full" width={42} height={42} />
        </Button>
        <MobileMenu
          anchorEl={anchorEl}
          onClose={onClose}
          open={open}
          navigationMenu={navigationMenu}
          path={location.pathname}
          myPriceList={myPriceList}
        />
      </Badge>
    );
  }

  return (
    <ul className="m-0 p-0 mb-4 flex justify-between items-stretch list-none">
      {navigationMenu.map((menuItem) => {
        const showBadge = menuItem.link === "/my-price-list";
        return (
          <li key={menuItem.link} className="flex items-end">
            <Badge
              overlap="rectangular"
              badgeContent={String(myPriceList.length)}
              invisible={!showBadge}
              classes={{ badge: "bg-[#EC5E0F]" }}
            >
              <Link
                to={menuItem.link}
                className={cn(
                  "block box-border text-white no-underline uppercase font-krub font-bold text-sm leading-[18px] text-center border border-transparent transition-all duration-300 px-1 py-0.5 hover:border-yellowPrimary",
                  {
                    "bg-[#E7B607A6]":
                      normalizePath(location.pathname) ===
                      normalizePath(menuItem.link),
                  }
                )}
              >
                {menuItem.caption}
              </Link>
            </Badge>
          </li>
        );
      })}
    </ul>
  );
};

export default MainNavigation;
