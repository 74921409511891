import React, { FC } from "react";
import EnvelopeIcon from "@/assets/icons/envelope_secondary.svg";
import PhoneIcon from "@/assets/icons/phone_secondary.svg";
import TimeIcon from "@/assets/icons/time_secondary.svg";
import FacebookIcon from "@/assets/icons/facebook_secondary.svg";
import AddressIcon from "@/assets/icons/address_primary.svg";
import InstagramIcon from "@/assets/icons/instagram_secondary.svg";
import YoutubeIcon from "@/assets/icons/youtube_secondary.svg";

const iconStyles = {
  marginRight: "4px",
  width: "14px",
  height: "14px",
};

interface ContactListProps {
  contactInfo: ContactInfo;
}

const ContactListDesktop: FC<ContactListProps> = ({ contactInfo }) => {
  return (
    <ul className="grid m-0 p-0 justify-between list-none gap-4 mui-md:grid-cols-2">
      <li className="flex flex-col justify-between h-full">
        <p className="text-textPrimary p-0 m-0">
          <a
            href={contactInfo.addressLinkExternal}
            target="_blank"
            rel="noreferrer noopener"
            aria-label="address"
            className="group hover:text-white no-underline text-textPrimary pl-1"
          >
            <AddressIcon
              style={iconStyles}
              className="inline-block fill-textPrimary group-hover:fill-white"
            />
            <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
              {contactInfo.addressText}
            </span>
          </a>
        </p>
      </li>
      <li className="flex flex-col justify-between h-full">
        <p className="text-textPrimary p-0 m-0">
          <a
            href={`callto:${contactInfo.phone}`}
            aria-label="phone"
            className="group hover:text-white no-underline text-textPrimary pl-1"
          >
            <PhoneIcon
              style={iconStyles}
              className="inline-block fill-textPrimary group-hover:fill-white"
            />
            <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
              {contactInfo.phoneText}
            </span>
          </a>
        </p>
      </li>
      <li className="flex flex-col justify-between h-full">
        <p className="text-textPrimary p-0 m-0">
          <a
            href={`mailto:${contactInfo.email}`}
            aria-label="email"
            className="group hover:text-white no-underline text-textPrimary pl-1"
          >
            <EnvelopeIcon
              style={iconStyles}
              className="inline-block fill-textPrimary group-hover:fill-white"
            />
            <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
              {contactInfo.email}
            </span>
          </a>
        </p>
      </li>
      <li className="flex flex-col justify-between h-full">
        <p className="text-textPrimary p-0 m-0">
          <a
            href={`mailto:${contactInfo.instagramLink}`}
            aria-label="instagram"
            className="group hover:text-white no-underline text-textPrimary pl-1"
          >
            <InstagramIcon
              style={iconStyles}
              className="inline-block fill-textPrimary group-hover:fill-white"
            />
            <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
              {contactInfo.instagramName}
            </span>
          </a>
        </p>
      </li>
      <li className="flex flex-col justify-between h-full">
        <p className="text-textPrimary p-0 m-0">
          <a
            href={`mailto:${contactInfo.youtubeLink}`}
            aria-label="youtube"
            className="group hover:text-white no-underline text-textPrimary pl-1"
          >
            <YoutubeIcon
              style={iconStyles}
              className="inline-block fill-textPrimary group-hover:fill-white"
            />
            <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
              {contactInfo.youtubeName}
            </span>
          </a>
        </p>
      </li>
    </ul>
  );
};

const ContactListMobile: FC<ContactListProps> = ({ contactInfo }) => {
  return (
    <ul
      aria-label="contact us"
      className="flex m-0 p-0 justify-between list-none"
    >
      <li className="list-none text-textPrimary p-0 m-0">
        <a
          href={`mailto:${contactInfo.email}`}
          aria-label="email"
          className="group hover:text-white no-underline text-textPrimary pl-1"
        >
          <EnvelopeIcon
            style={iconStyles}
            className="inline-block fill-textPrimary group-hover:fill-white"
          />
          <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
            {contactInfo.email}
          </span>
        </a>
      </li>
      <li className="list-none text-textPrimary p-0 m-0 hidden mui-md:list-item">
        <a className="group hover:text-white no-underline text-textPrimary pl-1">
          <TimeIcon
            style={iconStyles}
            className="inline-block fill-textPrimary group-hover:fill-white"
          />
          <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
            {contactInfo.workingTime}
          </span>
        </a>
      </li>
      <li className="list-none text-textPrimary p-0 m-0">
        <a
          href={`callto:${contactInfo.phone}`}
          aria-label="phone"
          className="group mui-md:mb-4 hover:text-white no-underline text-textPrimary pl-1"
        >
          <PhoneIcon
            style={iconStyles}
            className="inline-block fill-textPrimary group-hover:fill-white"
          />
          <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
            {contactInfo.phoneText}
          </span>
        </a>
      </li>
      <li className="list-none text-textPrimary p-0 m-0">
        <a
          href={contactInfo.addressLinkExternal}
          target="_blank"
          rel="noreferrer noopener"
          aria-label="address"
          className="group hover:text-white no-underline text-textPrimary pl-1"
        >
          <AddressIcon
            style={iconStyles}
            className="inline-block fill-textPrimary group-hover:fill-white"
          />
          <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
            {contactInfo.addressText}
          </span>
        </a>
      </li>
      <li className="list-none text-textPrimary p-0 m-0">
        <a
          href={contactInfo.facebookLink}
          target="_blank"
          aria-label="facebook"
          rel="noreferrer noopener"
          className="group hover:text-white no-underline text-textPrimary pl-1"
        >
          <FacebookIcon
            style={iconStyles}
            className="inline-block fill-textPrimary group-hover:fill-white"
          />
          <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
            our facebook link
          </span>
        </a>
      </li>
      <li className="list-none text-textPrimary p-0 m-0">
        <a
          href={contactInfo.instagramLink}
          target="_blank"
          aria-label="facebook"
          rel="noreferrer noopener"
          className="group hover:text-white no-underline text-textPrimary pl-1"
        >
          <InstagramIcon
            style={iconStyles}
            className="inline-block fill-textPrimary group-hover:fill-white"
          />
          <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
            our instagram link
          </span>
        </a>
      </li>
      <li className="list-none text-textPrimary p-0 m-0">
        <a
          href={contactInfo.youtubeLink}
          target="_blank"
          aria-label="facebook"
          rel="noreferrer noopener"
          className="group hover:text-white no-underline text-textPrimary pl-1"
        >
          <YoutubeIcon
            style={iconStyles}
            className="inline-block fill-textPrimary group-hover:fill-white"
          />
          <span className="hidden mui-md:inline font-prompt not-italic text-sm leading-[1.6] align-bottom">
            our youtube link
          </span>
        </a>
      </li>
    </ul>
  );
};

const ContactList: FC<ContactListProps> = ({ contactInfo }) => (
  <>
    <div className="hidden mui-md:block">
      <ContactListDesktop contactInfo={contactInfo} />
    </div>
    <div className="block mui-md:hidden">
      <ContactListMobile contactInfo={contactInfo} />
    </div>
  </>
);

export default ContactList;
