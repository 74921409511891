import React, { FC } from "react";
import { Menu, MenuItem, Badge } from "@mui/material";
import { navigate } from "gatsby";

interface MobileMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  open: boolean;
  navigationMenu: { link: string; caption: string }[];
  path: string;
  myPriceList: {
    id: string;
    quantity: number;
  }[];
}

const MobileMenu: FC<MobileMenuProps> = ({
  anchorEl,
  onClose,
  open,
  navigationMenu,
  path,
  myPriceList,
}) => {
  const handleMenuClick = (link: string) => {
    navigate(link);
    onClose();
  };

  return (
    <Menu
      role="navigation"
      slotProps={{
        paper: {
          className: "rounded-none !top-16",
        },
      }}
      aria-label="main navigation"
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
    >
      {navigationMenu.map((menuItem) => {
        return (
          <MenuItem
            color="white"
            key={menuItem.link}
            selected={path.includes(menuItem.link)}
            className="font-krub !text-xs uppercase [text-shadow:_0px_4px_4px_rgba(27,69,33,0.25)] !text-white !font-bold leading-[3]"
            onClick={() => handleMenuClick(menuItem.link)}
          >
            <Badge
              key={menuItem.link}
              overlap="rectangular"
              badgeContent={myPriceList.length}
              invisible={menuItem.link !== "/my-price-list"}
              classes={{ badge: "bg-textAccent top-3 -right-3" }}
            >
              {menuItem.caption}
            </Badge>
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default MobileMenu;
