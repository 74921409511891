import React, { FC, useContext } from "react";
import { Link } from "gatsby";
import ContactList from "./ContactListFooter";
import { FooterHeightSetContext } from "../../../context/footerHeightContext";
import useDimensions from "react-cool-dimensions";
import Designers from "./Designers";
import { StaticImage } from "gatsby-plugin-image";

interface FooterProps {
  contactInfo: ContactInfo;
  logoOld: string;
}

const Footer: FC<FooterProps> = ({ contactInfo, logoOld }) => {
  const { observe } = useDimensions({
    useBorderBoxSize: true, // Tell the hook to measure based on the border-box size, default is false
    onResize: ({ height, observe, unobserve }) => {
      setFooterHeight(Math.round(height) || 0);
      unobserve();
      observe();
    },
  });

  const setFooterHeight = useContext(FooterHeightSetContext);

  return (
    <footer
      ref={observe}
      className="relative overflow-hidden w-full bg-[rgba(236,201,77,0.69)] p-3 px-5 max-[360px]:px-3"
    >
      <div className="max-w-screen-mui-md mx-auto">
        <div className="mui-md:px-4">
          <div className="grid grid-cols-[4fr_1fr] gap-12 flex-nowrap items-center justify-between">
            <div className="flex-grow">
              <div className="grid grid-cols-[3fr_1fr]">
                <div className="flex-grow">
                  <ContactList contactInfo={contactInfo} />
                </div>
                <div className="max-mui-md:hidden">
                  <Link to="/" className="inline-block">
                    <StaticImage
                      src="../../../images/logo-old.png"
                      placeholder="dominantColor"
                      layout="constrained"
                      quality={15}
                      width={114}
                      className="block w-[85px] h-auto mui-md:w-[114px]"
                      alt="Farm equipment logo"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex shrink-0 justify-end">
              <Link to="/" className="mui-md:hidden inline-block">
                <StaticImage
                  src="../../../images/logo-old.png"
                  placeholder="dominantColor"
                  layout="constrained"
                  quality={15}
                  width={114}
                  className="block w-[85px] h-auto mui-md:w-[114px]"
                  alt="Farm equipment logo"
                />
              </Link>
              <Designers className="max-mui-md:hidden" />
            </div>
          </div>
        </div>
      </div>
      <Designers className="mui-md:hidden mt-4" />
    </footer>
  );
};

export default Footer;
